/* eslint no-lonely-if: 0 */
import { html, css, LitElement } from 'lit';
import { footerStyles } from './FooterStyles.js';
import { FooterShortTemplate } from './FooterShortTemplate.js'; // short footer design
import { isDoNotSell } from '../utils/isDoNotSell.js';
import { getWorkingEnv } from '../utils/getEnv.js';
import { resetCSS } from '../utils/resetCSS.js';
import { getPrivacyStub } from '../utils/getPrivacyStub.js';

export class FooterWPDP extends LitElement {
  static get styles() {
    if (resetCSS() || getWorkingEnv('creativegroup')) {
      return [
        css`
          :host {
            font-family: inherit;
          }
        `,
      ];
    }

    return [
      footerStyles,
      css`
        .text-dark-mode {
          color: #fff;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.template = document.createElement('template');
    /**
     * Handling potential footer conditions
     *
     * @param   {[type]}  isDoNotSell  [isDoNotSell description]
     *
     *  Footer conditions include:
     *    - No privacy options (default condition)
     *    - Privacy Choices Link
     *    - Privacy Choices Link + CA link
     *    - European Ad Consent choices link
     *
     * @return  {[type]}               [return description]
     */
    const {
      isDoNotSellCondition,
      isDoNotSellConditionCA,
      isDoNotSellConditionEAC,
    } = isDoNotSell();

    const { privacyApi } = getPrivacyStub();
    const isPrivacyApiAvailable = !!privacyApi;
    const geoInfo = isPrivacyApiAvailable ? privacyApi('GEO') : '';

    const fetchFooterTemplate = type => {
      this.template.innerHTML = FooterShortTemplate(type);
    };

    // privacy API is not available
    // fallback to previous logic

    if (!isPrivacyApiAvailable) {
      console.log('PrivacyApi is not available. Fallback in place.');
      if (isDoNotSellConditionEAC) {
        // renders the EAC footer if the user is in the EU
        fetchFooterTemplate('eac');
      } else if (isDoNotSellConditionCA) {
        // Renders the CCPA footer if the user is in CA
        fetchFooterTemplate('ccpa');
      } else if (isDoNotSellCondition) {
        // Renders the Do Not Sell footer if the user is in a Do Not Sell state [CO, CT, UT, VA]
        fetchFooterTemplate('donotsell');
      } else {
        fetchFooterTemplate('standard');
      }
    } else {
      // must be first in logic because geoInfo is false and EAC is false, then downstream else if will fail
      if (!geoInfo && !isDoNotSellConditionEAC) {
        fetchFooterTemplate('standard');
        // must be second because geoInfo is false, and EAC is true
      } else if (
        (!geoInfo && isDoNotSellConditionEAC) ||
        geoInfo.includes('OPT_IN|EEA')
      ) {
        // So this should check the stub is false AND the wp_ucp cookie exists and includes EAC
        // renders the EAC footer if the user is in the EU
        fetchFooterTemplate('eac');
      } else if (geoInfo.includes('DNS|') && !isDoNotSellConditionCA) {
        // Renders the Do Not Sell footer if the user is in a Do Not Sell states
        // and the user is not in CA
        fetchFooterTemplate('donotsell');
      } else if (isDoNotSellConditionCA) {
        // Renders the CCPA footer if the user is in CA
        fetchFooterTemplate('ccpa');
      } else if (geoInfo.includes('OPT_IN')) {
        fetchFooterTemplate('standard');
      } else {
        fetchFooterTemplate('standard');
      }
    }

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(this.template.content.cloneNode(true));
  }

  connectedCallback() {
    super.connectedCallback();
    this.privacyStub = getPrivacyStub();
    // console.log('Connected Callback basic');
  }

  render() {
    return html`<!-- Data Privacy Footer -->`;
  }
}
