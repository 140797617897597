/* eslint import/no-unresolved: 0 */
import { FooterWPDP } from './src/Footers/FooterWPDP.js';
import { fetchFooter } from './src/Footers/fetchFooter.js';
import { hasFooter } from './src/Footers/hasFooter.js';
import { initiateJucidSync } from './src/utils/jucid-sync.js';

customElements.define('footer-wpdp', FooterWPDP);

fetchFooter(hasFooter());

try {
  initiateJucidSync();
} catch (e) {
  console.debug(e);
}

export { FooterWPDP, fetchFooter, hasFooter };
