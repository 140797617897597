export function getPrivacyStub() {
  let privacyStub = null;
  let privacyApi = null;
  // check if window object is available
  if (typeof window === 'undefined') {
    return null;
  }

  if (window !== undefined && window.__privacystub) {
    privacyStub = window.__privacystub;
    privacyApi = window.__privacyapi;
    return { privacyStub, privacyApi };
  }
  return { privacyStub, privacyApi };
}
