import { getEnv } from './getEnv.js';

// TODO: Switch to the subs-sdk version
// This currently has an error about process.env.NODE_ENV
async function queryIframe({
  containerEl,
  iframeSrc,
  postMessageAction,
  postMessageResponseAction,
  timeout = 2e3,
}) {
  if (
    containerEl instanceof HTMLDivElement &&
    iframeSrc instanceof URL &&
    postMessageAction &&
    postMessageResponseAction
  ) {
    const iframeEl = document.createElement('iframe');
    iframeEl.id = 'query-iframe';
    iframeEl.style = 'border: none;';
    iframeEl.width = '0';
    iframeEl.height = '0';

    const data = await Promise.race([
      new Promise(resolve => {
        const onload = () => {
          const messageHandler = event => {
            if (event.data.action === postMessageResponseAction) {
              window.removeEventListener('message', messageHandler);
              resolve(event.data);
            }
          };

          window.addEventListener('message', messageHandler, true);

          iframeEl.contentWindow?.postMessage(
            {
              action: postMessageAction,
            },
            iframeSrc.origin
          );
        };

        iframeEl.addEventListener('load', onload);

        iframeEl.src = iframeSrc.toString();

        containerEl.appendChild(iframeEl);
      }),
      new Promise((_resolve, reject) => {
        setTimeout(reject, timeout);
      }),
    ])
      .catch(() => Promise.resolve(null))
      .finally(() => {
        iframeEl.parentNode?.removeChild(iframeEl);
      });

    return data;
  }

  return null;
}

function getJucidIframeUrl(env) {
  switch (env) {
    case 'stage':
    case 'fStage':
      return 'https://subs-stage.washingtonpost.com/jucid_iframe/jucid_iframe.html';
    case 'qa':
    case 'fQa':
      return 'https://subscribe.digitalink.com/jucid_iframe/jucid_iframe.html';
    case 'prod':
    default:
      return 'https://www.washingtonpost.com/subscribe/jucid_iframe/jucid_iframe.html';
  }
}

export async function initiateJucidSync() {
  const searchParams = new URLSearchParams(window.location.search);

  if (
    window.location.origin === 'https://subscription.washingtonpost.com' ||
    searchParams.get('test_jucid_sync') === 'true' ||
    localStorage.getItem('test_jucid_sync') === 'true'
  ) {
    const firstElement = document.body.firstChild;
    const containerEl = document.createElement('div');
    containerEl.style = 'display: none;';
    containerEl.setAttribute('data-test-id', 'jucid-sync-container');
    firstElement.before(containerEl);

    const env = getEnv();
    const iframeUrl = getJucidIframeUrl(env);

    const data = await queryIframe({
      containerEl,
      iframeSrc: new URL(iframeUrl),
      postMessageAction: 'getJucid',
      postMessageResponseAction: 'getJucidResponse',
    });

    if (data && data.jucid) {
      localStorage.setItem('uuid', data.jucid);
    }
  }
}
