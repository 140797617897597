/**
 * @description - Find preexisting footer and return boolean and HTMLElement
 * @returns {preexisting: boolean, hasFooterEL: HTMLElement}
 * @examples footer#standard-footer, footer#ccpa-footer
 * */

export function hasFooter() {
  const hasFooterEL =
    document.querySelector('#ccpa-footer') ||
    document.querySelector('#standard-footer');
  let preexisting = false;
  // TODO: import subs sdk for geo-location check

  if (hasFooterEL !== null) {
    preexisting = true;
  } else {
    preexisting = false;
  }

  return {
    preexisting,
    hasFooterEL,
  };
}
