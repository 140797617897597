import { css } from 'lit';

export const footerStyles = css`
  :host {
    display: block;
    font-family: inherit;
  }

  .background-white {
    background: #fff;
  }
  .gray-dark {
    color: #666;
  }

  .w-100 {
    width: 100%;
  }
  .h-100 {
    height: 100%;
  }
  .pl-5 {
    padding-left: 5px;
  }
  .font-xxxxs {
    font-size: 0.75rem;
  }
  .center {
    text-align: center;
  }

  .db {
    display: block;
  }
  .flex {
    display: flex;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-row {
    flex-direction: row;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-center {
    justify-content: center;
  }

  .items-self-center {
    align-self: center;
  }
  .align-center {
    align-items: center;
  }
  ol,
  ul {
    padding-left: 2.5rem;
    margin: 0.625rem 0;
  }
  .list-unstyled {
    list-style-type: none;
  }

  .ma-auto {
    margin: auto;
  }
  .mx-4 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mw-1200 {
    max-width: 1200px;
  }

  .pr-md {
    padding-right: 24px;
  }
  .pl-md {
    padding-left: 24px;
  }
  .pa-0 {
    padding: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pb-xs {
    padding-bottom: 8px;
  }
  .pt-xs {
    padding-top: 8px;
  }
  .pl-xs {
    padding-left: 8px;
  }
  .pl-xxs {
    padding-left: 4px;
  }
  .pt-xxs {
    padding-top: 4px;
  }
  .pa-xxs {
    padding: 4px;
    padding-right: 4px;
    padding-left: 4px;
  }

  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }

  @media only screen and (min-width: 768px) {
    .md\:flex-row {
      flex-direction: row;
    }
    .flex-ns-row {
      flex-direction: row;
    }
    .font-xxxs-ns {
      font-size: 0.875rem;
    }
    .order-1-ns {
      order: 1;
    }
    .dib-ns {
      display: inline-block;
    }
  }
  footer-wpdp::part(privacy-options-img) {
    height: 14px;
    width: 26px;
  }
`;
