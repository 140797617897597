/**
 * @description creates the html custom tag <footer-wpdp></footer-wpdp> and appends to the body of the document. If hasFooter is truthy, it replaces the footer with our web component
 * @param {preexisting: boolean, hasFooterEL: HTMLElement}
 * */

export function fetchFooter({ preexisting, hasFooterEL }) {
  const footerWPDP = document.createElement('footer-wpdp');

  // check if footer exists
  if (preexisting) {
    // replace preexisting footer with footerWPDP
    hasFooterEL.replaceWith(footerWPDP);
  } else {
    // otherwise simply append to the body of the page
    document.body.appendChild(footerWPDP);
  }
}
