import { getCookie } from './getCookies.js';

// An array of states that are not allowed to sell user data
export const doNotSellStates = [
  'CA',
  'CO',
  'CT',
  'DE',
  'MT',
  'NH',
  'NJ',
  'OR',
  'TX',
  'UT',
  'VA',
];

// Check if the user is in an EEA region and if they are subject to European Ad Consent.
// If so, they should have a wp_ucp cookie with the EAC flag set.
export function isEacLink(ucpCookie) {
  if (
    typeof window !== 'undefined' &&
    window.document &&
    /EAC/.test(ucpCookie) &&
    window.__tcfapi === undefined
  ) {
    return true;
  }
  return false;
}

// Takes in the wapoIdentity object and checks if the user's addressState is in the doNotSellStates array.
export function processUserStateLS(wapoIdentity) {
  let isAddressApplicable = false;
  isAddressApplicable = doNotSellStates.includes(wapoIdentity.addressState);

  // If the user's addressState is in the doNotSellStates array, return true.
  if (isAddressApplicable) {
    return {
      isDoNotSellCondition: isAddressApplicable,
      isDoNotSellConditionCA: wapoIdentity.addressState === 'CA',
      isDoNotSellConditionEAC: isEacLink(getCookie('wp_ucp')),
    };
  }
  return isAddressApplicable;
}

// Takes in the wp_geo cookie and conditions object and checks if the user's geo cookie is in a state
// where Do Not Sell rights apply setting the isDoNotSellCondition and/or isDoNotSellConditionCA flags appropriately.
export function processGeoCookie(geoState, conditions) {
  let { isDoNotSellCondition, isDoNotSellConditionCA } = conditions;
  if (geoState) {
    const [country, state] = (geoState && geoState.split('|')) || [];
    if (country === 'US' && doNotSellStates.includes(state)) {
      isDoNotSellCondition = true;
    } else {
      isDoNotSellCondition = false;
    }

    if (country === 'US' && state === 'CA') {
      isDoNotSellConditionCA = true;
    } else {
      isDoNotSellConditionCA = false;
    }
  }
  return {
    isDoNotSellCondition,
    isDoNotSellConditionCA,
    isDoNotSellConditionEAC: conditions.isDoNotSellConditionEAC,
  };
}

// This is the main function that is exported. It first checks if the user has a wapo_identity object in localStorage.
// If successful it calls processUserStateLS to check if the user's addressState is in the doNotSellStates array and returns true if so.
// If not, it calls processGeoCookie to check if the user's geo cookie is in a state where Do Not Sell rights apply.
export function isDoNotSell() {
  try {
    if (
      typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem('wapo_identity')
    ) {
      const wapoIdentity = JSON.parse(
        window.localStorage.getItem('wapo_identity')
      );
      // Call processUserStateLS function with the wapoIdentity object and stores the result.
      const result = processUserStateLS(wapoIdentity);

      // If the result is true, return it.
      // Returns three boolean values: isDoNotSellCondition, isDoNotSellConditionCA, isDoNotSellConditionEAC.
      if (result !== false) {
        return result;
      }
    }
  } catch (e) {
    // console.error(e);
  }

  // eslint-disable-next-line prefer-const
  let isDoNotSellCondition = false;
  // eslint-disable-next-line prefer-const
  let isDoNotSellConditionCA = false;
  // eslint-disable-next-line prefer-const
  let isDoNotSellConditionEAC = isEacLink(getCookie('wp_ucp'));

  // eslint-disable-next-line prefer-const
  let conditions = {
    isDoNotSellCondition,
    isDoNotSellConditionCA,
    isDoNotSellConditionEAC,
  };

  // Sets the geoState to the wp_geo cookie.
  const geoState = getCookie('wp_geo');

  return processGeoCookie(geoState, conditions);
}

export default isDoNotSell;
